export default defineNuxtRouteMiddleware(() => {
  if (process.server) {
    return;
  }

  const pb = usePocketBase();
  if (!pb.authStore.isValid) {
    return navigateTo('/user/login');
  }
});
